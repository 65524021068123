


































































































































































import { Component, Vue } from 'vue-property-decorator';
import UserAccountModule from '@/store/modules/UserAccountModule';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class SignupPage extends Vue {
  username: string | null = null;
  email: string | null = null;
  password: string | null = null;
  showPassword = false;
  showValidateMessage = false;

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  async join() {
    if (!this.validateAll) {
      this.showValidateMessage = true;
    } else {
      this.showValidateMessage = false;
      await UserAccountModule.signUp({
        username: this.username!,
        email: this.email!,
        password: this.password!,
      });
    }
  }

  get validateAll(): boolean {
    return (
      this.validateUsername === true &&
      this.validateEmail === true &&
      this.validatePassword === true
    );
  }

  get validateUsername() {
    if (this.username) {
      return (
        this.username.length >= 8 &&
        this.username.length <= 100 &&
        !this.isValidEmail(this.username!)
      );
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get validatePassword() {
    if (this.password) {
      return this.password!.length >= 8;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get validateEmail() {
    if (this.email) {
      return this.isValidEmail(this.email!);
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  //https://www.w3resource.com/javascript/form/email-validation.php
  isValidEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  get signupProcessing() {
    return UserAccountModule.signupProcessing;
  }

  get signupError() {
    return UserAccountModule.signupError;
  }

  get inputType() {
    return this.showPassword ? 'text' : 'password';
  }

  get getIcon() {
    return this.showPassword ? 'eye-slash' : 'eye';
  }
}
